body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./public/fonts/montserrat-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roman";
  src: local("Roman"),
    url("./public/fonts/roman.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat Bold"),
    url("./public/fonts/montserrat-extra-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Light";
  src: local("Montserrat Light"),
    url("./public/fonts/montserrat-light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Regular";
  src: local("Montserrat Regular"),
    url("./public/fonts/montserrat-regular.ttf") format("truetype");
}

/* #menu a {
  font-family: "Roman" !important;
  font-weight: bold !important;
} */

.montserrat {
  font-family: "Montserrat" !important;
}

.montserrat-bold {
  font-family: "Montserrat Bold" !important;
}

.montserrat-light {
  font-family: "Montserrat Light" !important;
}

.montserrat-regular {
  font-family: "Montserrat Regular" !important;
}
